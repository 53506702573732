// CategoryList.js
import React from "react";
import { graphql } from "gatsby";
import Layout from "../../layout";
import BlogCard from "../../BlogCard";
import Paginator from "../../Paginator";
import capitalise from "../../../helpers/capitalise";
export default function BlogCategoryPage({pageContext, data}) {

  let {edges: posts} = data.allMarkdownRemark;

  let { humanPageNumber, numberOfPages, nextPagePath, previousPagePath, category, tag } = pageContext;

  return (
    <Layout width_restrict_all={true} meta_title={`${capitalise(tag || category)} Blog Posts${humanPageNumber !== 1 ? ` (Pg. ${humanPageNumber})` : ''}`} meta_desc={`Check out all of Jonny's ${capitalise(tag || category)} blog posts!`} meta_img={`/assets/metadata_images/default/blog.png`}>

          {category ? <h1>{capitalise(category)} Blog Posts</h1> : null}
          {tag ? <h1>Blog Posts Tagged: {capitalise(tag)}</h1> : null}
          
          <div className={`listing-wrapper flex-container-column`}>

            {posts.map((post, i) => {

                let frontmatter = post.node.frontmatter;
                let slug = post.node.fields.slug;
                let img_fluid = post.node.frontmatter.image.childImageSharp.fluid
                
                return <BlogCard frontmatter={frontmatter} slug={slug} img_fluid={img_fluid} key={i}  />

            })}

          </div>

          <Paginator 
            current_page_number={humanPageNumber} 
            total_no_of_pages={numberOfPages}
            next_page_path={nextPagePath}
            previous_page_path={previousPagePath}
          />

    </Layout>
  )

}

export const query = graphql`
  query ($skip: Int!, $limit: Int!, $frontmatter_filter: MarkdownRemarkFrontmatterFilterInput) {
    allMarkdownRemark(
      filter: {
        collection: {eq: "blogpost"},
  			frontmatter: $frontmatter_filter
      }
      skip: $skip
      limit: $limit
      sort: { fields: [frontmatter___date], order: DESC }
      ) {
      edges {
        node {
          frontmatter {
            title
            category
            tags
            description
            date(formatString: "MMM D, YYYY")
            image {
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
          fields {
              slug
          }
        }
      }
    }
  }
`;